import React, {
  Fragment,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useIntl } from "react-intl";

import { Table, Dropdown, Button, Menu, Input } from "antd";
import { Icons } from "components";
import "./tableView.scss";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import printJS from "print-js";

const TableView = forwardRef((props, ref) => {
  const { sticky, loading, columns, items, count, onRefreshTable, scroll, expandable, rowKey, rowSelection, gridDataForPrinting } = props;
  useImperativeHandle(ref, () => ({
    refresh: () => {
      refreshData(page, pageLimit, openSearch);
    },
  }));

  const { messages } = useIntl();
  const [openSearch, setOpenSearch] = useState();
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const { Search } = Input;

  //evento de refreco de data
  const refreshData = useCallback((newPage, newPageLimit, newOpenSearch) => {
    let currentPage = page;
    let currentPageLimit = pageLimit;
    let currentOpenSearch = openSearch;

    if (currentPage != newPage) {
      currentPage = newPage;
      setPage(newPage);
    }

    if (currentPageLimit != newPageLimit) {
      currentPageLimit = newPageLimit;
      setPageLimit(newPageLimit);
    }

    if (currentOpenSearch != newOpenSearch) {
      currentOpenSearch = newOpenSearch;
      setOpenSearch(newOpenSearch);
    }

    onRefreshTable &&
      onRefreshTable(
        currentOpenSearch,
        currentPageLimit,
        (currentPage - 1) * currentPageLimit
      );
  });

  //let searchTimer;
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx"

  const exportToExcel = useCallback((entryData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(entryData);
    const wb = { Sheets: { exportedData: ws }, SheetNames: ["exportedData"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const exportedData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(exportedData, fileName + fileExtension);
  })

  return (
    <Fragment>
      <div className="row mb-2">
        {/* <Search
            allowClear
            placeholder={messages["search"]}
            onChange={(e) => {
              clearTimeout(searchTimer);
              searchTimer = setTimeout(() => {
                refreshData(page, pageLimit, e.target.value);
              }, 1000);
            }}
            onSearch={(value) => {
              refreshData(page, pageLimit, value);
            }}
          /> */}
        {/* 
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground">

                <input className="form-control" placeholder="Search" />
                <span className="search-magnifier-icon">
                    <Icons family='ai' name='AiOutlineSearch' />
                </span>
                <span className="search-delete-icon d-none">
                    <Icons family='ai' name='AiOutlineClose' />
                </span>
            </div>
            */}

        <div className="col-12 text-md-end mb-1">
          <div className="d-inline-block">

            <div className="d-inline-block ps-1">
              <Button
                className="btn btn-icon btn-icon-only btn-foreground-alternate shadow"
                icon={<Icons family="ai" name="AiOutlineReload" />}
                onClick={() => refreshData(page, pageLimit, openSearch)}
              />
            </div>

            <div className="d-inline-block ps-1">
              <Button
                className="btn btn-icon btn-icon-only btn-foreground-alternate shadow"
                onClick={() => printJS({
                  printable: "grid-table",
                  type: "html",
                  targetStyle: ["color", "font-size", "background-color", "font-family", "width", "text-align", "background", "font-weight", "border-bottom", "position", "padding", "margin", "border-color", "border"],
                  header: document.querySelector("h1#title").textContent,
                  documentTitle: document.querySelector("h1#title").textContent,
                })}
                icon={<Icons family="ai" name="AiOutlinePrinter" />}
              />
            </div>

            <div className="d-inline-block ps-1">
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item
                      key={'Excel'}
                      onClick={() => {
                        if (!loading && gridDataForPrinting && (gridDataForPrinting.length !== 0)) {
                          exportToExcel(props.gridDataForPrinting, props.items[0]?.__typename ? `ServiLogística Orion - ${document.querySelector("h1#title").textContent}` : "Exportación desde ServiLogística Orion");
                        }
                      }}
                    >
                      Excel
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  className="btn btn-icon btn-icon-only btn-foreground-alternate shadow"
                  icon={<Icons family="ai" name="AiOutlineCloudDownload" />}
                />
              </Dropdown>
            </div>

            <div className="d-inline-block ps-1">

              <Dropdown
                className="h-100"
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={() =>
                        pageLimit != 3 && refreshData(1, 10, openSearch)
                      }
                    >
                      10 {messages["rows"]}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() =>
                        pageLimit != 25 && refreshData(1, 25, openSearch)
                      }
                    >
                      25 {messages["rows"]}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() =>
                        pageLimit != 50 && refreshData(1, 50, openSearch)
                      }
                    >
                      50 {messages["rows"]}
                    </Menu.Item>
                    {count > 50 && <Menu.Item
                      onClick={() =>
                        pageLimit != count && refreshData(1, count, openSearch)
                      }
                    >
                      {count} - {messages["rows"]}
                    </Menu.Item>

                    }

                  </Menu>
                }
              >
                <Button className="btn btn-foreground-alternate">
                  {pageLimit} {messages["rows"]}
                </Button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-0">
        <div className="col-12">
          {/*  <Table columns={columns} dataSource={data} pagination={{ pageSize: 2 }} /> */}
          <Table
            id="grid-table"
            rowKey={rowKey || "id"}
            loading={loading}
            columns={columns}
            dataSource={items}
            expandable={expandable}
            pagination={{ total: count, pageSize: pageLimit, current: page }}
            onChange={(pagination, filters, sorter) => {
              refreshData(pagination.current, pageLimit, openSearch);
            }}
            sticky={sticky}
            tableLayout="fixed"
            scroll={scroll}
            className="tableView overflow-hidden"
            rowSelection={rowSelection}
          />
        </div>
      </div>
    </Fragment>
  );
});

export default TableView;
