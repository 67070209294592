const debug = localStorage.getItem("debug") == "true" || false;
const url = debug ? "http://localhost:8001" : "https://nixtrack.ingeniosoft.co";

console.log("url", url, debug)

export const endpoints = {
    GRAPHQL_AUTH: `${url}/api/auth`,
    GRAPHQL_GENERAL: `${url}/api/data`
}

export const htmlInitialAttributes = {
    "lang": "es",
    "data-placement": "vertical",
    "data-behaviour": "unpinned",
    "data-layout": "fluid",
    "data-radius": "standard",
    "data-color": "light-odc",
    "data-navcolo": "default",
    "data-show": "true",
    "data-menu-animate": "hidden"
}