import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  notification,
  Select,
  Image,
  Checkbox,
  Cascader,
  DatePicker
} from "antd";
import { InputFile } from "components";

import { getCatalog, editSeguimientoClose, editSeguimientoSave, clearRedux } from "config/redux/actions";

import "./styles.css"
import { useCitiesCascader } from "../../../../config/helpers/Hooks";
import moment from "moment";


const EditSeguimientoComp = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [form] = Form.useForm();

  const [id, setId] = useState();
  const [originCityId, setOriginCityId] = useState(0);
  const [destinyCityId, setDestinyCityId] = useState(0);
  const [activeStatus, setActiveStatus] = useState(0)


  const { isOpen, data, loading, result } = useSelector(
    (state) => state.editSeguimientoRedux
  );
  const { loadingVehicles, vehicles, clients, loadingClients, accompanists, loadingAccompanist, statuses, loadingStatuses } = useSelector(
    (state) => state.commonRedux
  );

  const isEdit = data ? true : false;

  const { cascaderOptions, cities, loadingCities, states, loadingStates } = useCitiesCascader();


  useEffect(() => {
    dispatch(getCatalog(["vehicles", "clients", "accompanists", "statuses"]));
  }, [])

  //carga inicial de un valor de edicion
  useEffect(() => {
    if (data && !id) {
      //Se guarda el id
      setId(data.IdSeguimiento);
      //Se hace set al formulario
      setOriginCityId(data?.IdCiudadOrigen);
      setDestinyCityId(data?.IdCiudadDestino);
      setActiveStatus(data?.IdEstado);
      form.setFields([
        { name: "IdCliente", value: data?.IdCliente },
        { name: "IdCarro", value: data?.IdCarro },
        { name: "IdCiudadOrigen", value: [cities.find(v => v.CityCode == data?.IdCiudadOrigen)?.DepartmentCode, data?.IdCiudadOrigen] },
        { name: "IdCiudadDestino", value: [cities.find(v => v.CityCode == data?.IdCiudadDestino)?.DepartmentCode, data?.IdCiudadDestino] },
        { name: "Orden", value: data?.Orden },
        { name: "Manifiesto", value: data?.Manifiesto },
        { name: "Aseguradora", value: data?.Aseguradora },
        { name: "Ruta", value: data?.Ruta },
        { name: "Kilometros", value: data?.Kilometros },
        { name: "TiempoEstimado", value: data?.TiempoEstimado },
        { name: "Restriccion", value: data?.Restriccion },
        { name: "Observacion", value: data?.Observacion },
        { name: "FechaSalida", value: moment(data?.FechaSalida) },
        { name: "FechaLlegada", value: data?.FechaLlegada && moment(data?.FechaLlegada) },
        { name: "IdEstado", value: data?.IdEstado },
        { name: "Link", value: data?.Link },
        { name: "NombreCond", value: data?.NombreCond },
        { name: "CelularCond", value: data?.CelularCond },
        { name: "IdAcompanante", value: data?.IdAcompanante },
      ]);
    }
  }, [data]);

  //se valida si hay un resultado de ejecucion
  useEffect(() => {
    if (result) {
      if (result.status) {
        notification.success({
          message: "Bien!!",
          description: "Se guardo exitosamente!!",
        });

        close(true);
      } else {
        notification.error({
          message: "Ocurrio un Error",
          description: messages[result.error] || result.error,
        });
      }
    }
  }, [result]);

  //metodo de cirre de la ventana
  const close = useCallback((refresh) => {
    dispatch(editSeguimientoClose());
    form.resetFields();
    setId();
    if (onClose) {
      onClose(refresh);//
    }

  });


  return (
    <Modal
      title={
        isEdit ? messages["editSeguimiento_titleUpdate"] : messages["editSeguimiento_title"]
      }
      visible={isOpen}
      onOk={() => { }}
      onCancel={() => close()}
      width={800}
      footer={null}
      maskClosable={false}
    >
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <Spin
                tip="Loading..."
                spinning={loading || loadingVehicles || loadingClients || loadingCities || loadingStates || loadingAccompanist}
              >
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={(values) => {
                    dispatch(
                      editSeguimientoSave({
                        ...values,
                        IdSeguimiento: data?.IdSeguimiento,
                        IdCiudadOrigen: originCityId,
                        IdCiudadDestino: destinyCityId,
                      }
                      )
                    );
                  }}
                >
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Orden"
                        label={messages["editSeguimiento_order"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="IdCliente"
                        label={messages["editSeguimiento_client"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Select
                          allowClear
                        >
                          {clients &&
                            clients.map((client, index) => {
                              return (
                                <Select.Option key={index} value={client.IdCliente}>
                                  {client.RazonSocial}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Manifiesto"
                        label={messages["editSeguimiento_manifest"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">

                      <Form.Item
                        name="IdCarro"
                        label={messages["editSeguimiento_vehicle"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {vehicles &&
                            vehicles.map((vehicle, index) => (
                              <Select.Option key={index} value={vehicle.IdCarro}>
                                {vehicle.Placa + " - " + vehicle.Marca + " " + vehicle.Modelo + " " + vehicle.Color}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Aseguradora"
                        label={messages["editSeguimiento_insurance"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Ruta"
                        label={messages["editSeguimiento_route"]}

                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="IdCiudadOrigen"
                        label={messages["editSeguimiento_originCity"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Cascader
                          allowClear
                          showSearch={{ filter: (inputValue, path) => path.some(option => (option.label).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,) }}
                          options={cascaderOptions}
                          onChange={v => v !== null && v !== undefined && v.length > 1 && setOriginCityId(prev => v[1])}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="IdCiudadDestino"
                        label={messages["editSeguimiento_destinyCity"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Cascader
                          showSearch={{ filter: (inputValue, path) => path.some(option => (option.label).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,) }}
                          options={cascaderOptions}
                          onChange={v => v !== null && v !== undefined && v.length > 1 && setDestinyCityId(prev => v[1])}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="Kilometros"
                        label={messages["editSeguimiento_kilometers"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="TiempoEstimado"
                        label={messages["editSeguimiento_estimatedTime"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="FechaSalida"
                        label={messages["editSeguimiento_startDate"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <DatePicker showTime />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="FechaLlegada"
                        label={messages["editSeguimiento_endDate"]}
                      >
                        <DatePicker showTime disabled={activeStatus != 4} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="NombreCond"
                        label={messages["editSeguimiento_driverName"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="CelularCond"
                        label={messages["editSeguimiento_driverPhone"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="IdAcompanante"
                        label={messages["editSeguimiento_accompanist"]}
                      //rules={[
                      //  {
                      //    required: true,
                      //    message: messages["required_field"],
                      //  },
                      //]}
                      >
                        <Select
                          allowClear
                        >
                          {accompanists &&
                            accompanists.map((accompanist, index) => {
                              return (
                                <Select.Option key={index} value={accompanist.IdAcompanante}>
                                  {accompanist.Nombre + " - " + accompanist.Cedula}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        name="IdEstado"
                        label={messages["editSeguimiento_status"]}
                        rules={[
                          {
                            required: true,
                            message: messages["required_field"],
                          },
                        ]}
                      >
                        <Select
                          onChange={(v) => setActiveStatus(v)}
                        >
                          {statuses &&
                            statuses.map((status, index) => {
                              return (
                                <Select.Option key={index} value={status.IdEstado} disabled={
                                  data == null
                                    ? !(status.IdEstado == 1 || status.IdEstado == 2)
                                    : data.IdEstado == 1
                                      ? !(status.IdEstado == 2 || status.IdEstado == 3)
                                      : data.IdEstado == 2
                                        ? !(status.IdEstado == 3 || status.IdEstado == 4)
                                        : !(status.IdEstado == 1 || status.IdEstado == 2)
                                }>
                                  {status.NombreEstado}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Form.Item
                        name="Link"
                        label={messages["editSeguimiento_link"]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Form.Item
                        name="Restriccion"
                        label={messages["editSeguimiento_restriction"]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Form.Item
                        name="Observacion"
                        label={messages["editSeguimiento_observation"]}
                      >
                        <Input.TextArea rows={4} />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <Button
                      loading={loading}
                      type="primary"
                      className="btn btn-alternate btn-end"
                      onClick={() => close()}
                    >
                      {messages["cancel"]}
                    </Button>
                    <Button
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      className="btn btn-primary btn-end"
                    >
                      {messages["save"]}
                    </Button>
                  </div>
                </Form>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditSeguimientoComp;
